import React from "react";

const Footer = () => {
  return (
    <div className="d-flex justify-content-center align-items-end footer-main">
      <p className="text-white">
      ©️ 2024 All Rights Reserved. OSICrew - <a target="_blank" href="https://www.osireklamajansi.com/">Ankara Reklam Ajansı</a>
      
      </p>
    </div>
  );
};

export default Footer;
